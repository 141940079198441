import React from "react"
import { Layout, SEO } from "../components"
import { OurWorkSection, ServicesSection } from "../pageSections/index"
import {
  ClientsSection,
  ChallengeSection,
  HeroSection,
  PrideSection,
  TeamSection,
  WhatClientsSaySection,
} from "../pageSections/startups"
import { startups, testimonials } from "../content"
import { useContentfulOurWork } from "../components/hooks/useContentfulOurWork"

const StartUps = () => {
  const { challenge, contentGroup1, heroText, metadata, services, pride } =
    startups
  const { title, shortDescription, projects } = useContentfulOurWork()

  return (
    <Layout title={metadata.title}>
      <SEO description={metadata.description} title={metadata.title} />
      <HeroSection heroText={heroText} />
      <ClientsSection darkMode={true} />
      <ChallengeSection content={challenge} darkMode={true} />
      <ServicesSection color="light-gray" services={services} />
      <TeamSection content={contentGroup1} />
      <PrideSection content={pride} />
      <OurWorkSection
        color="light-gray"
        projects={projects}
        metadata={{ title, shortDescription }}
      />
      <WhatClientsSaySection testimonial={testimonials[0]} />
    </Layout>
  )
}

export default StartUps
